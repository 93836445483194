<template>
    <div class="flex items-center space-x-4 cursor-pointer" @click="openIframePopup">
      <div  style="height:42px;"
        class="font-bold tracking-wide text-beige font-sans bg-salmon-100 hover:bg-salmon-50 px-2 rounded-md flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="h-6 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
        </svg>
        <span class="uppercase ml-2 tracking-widest text-lg">{{ $t('home.book') }}</span>
      </div>
    </div>
</template>

<script>
export default {
  name: "Book",
  components: {
  },
  mounted() {
    let coverManagerScript = document.createElement('script')
    coverManagerScript.setAttribute('src', 'https://www.covermanager.com/js/iframeResizer.min.js')
    coverManagerScript.setAttribute('async', 'async')
    document.head.appendChild(coverManagerScript)
  },
  methods: {
    openIframePopup() {
      let language = 'it';
      language = this.$root.$i18n.locale; // Get the current language
      let url = 'https://www.covermanager.com/reservation/module_restaurant/il-cantuccio/';

      // Append the language-specific part to the URL
      if (language === 'en') {
        url += 'english';
      } else if (language === 'it') {
        url += 'italian';
      }

      const iframe = document.createElement('iframe');
      iframe.setAttribute('id', 'il-cantuccio');
      iframe.setAttribute('title', 'Prenotazioni');
      iframe.setAttribute('allow', 'payment');
      iframe.setAttribute('onLoad', 'iFrameResize()');
      iframe.src = url;
      iframe.style.position = 'fixed';
      iframe.style.top = '50%';
      iframe.style.left = '50%';
      iframe.style.transform = 'translate(-50%, -50%)';
      iframe.style.width = '80%';
      iframe.style.height = '80%';
      iframe.style.zIndex = '1000';
      iframe.style.border = 'none';
      iframe.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.5)';
      iframe.style.overflow = 'auto';

      const overlay = document.createElement('div');
      overlay.setAttribute('id', 'booking-overlay');
      overlay.style.position = 'fixed';
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
      overlay.style.zIndex = '999';
      overlay.appendChild(iframe);

      iframe.addEventListener('load', (event) => {
        event.target.setAttribute('scrolling', 'yes');
        event.target.style.overflow = 'auto';
      });

      overlay.addEventListener('click', () => {
        document.body.removeChild(overlay);
        document.body.style.overflow = ''; // Re-enable background scrolling
      });

      document.body.appendChild(overlay);
      document.body.style.overflow = 'hidden'; // Disable background scrolling
    }
  }
};
</script>